import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Field, FieldWrapper, Form, FormElement } from '@progress/kendo-react-form';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CInput from '../../../../../components/form-components/CInput';
import { isEmpty } from '../../../../../components/form-components/helpers/validation-functions';
import Spinner from '../../../../../components/Loaders/Spinner/Spinner';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import useAppBundleAlias from '../../../../../helpers/hooks/storeHooks/useAppBundleAlias';
import useAppBundleVersion from '../../../../../helpers/hooks/storeHooks/useAppBundleVersion';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import {
  getAppBundleAlias,
  setAppBundleAlias,
  postAppBundleAlias,
  putAppBundleAlias,
} from '../../../../../stores/appBundleAlias/reducer';
import { getAppBundleVersionList } from '../../../../../stores/appBundleVersion/reducer';
import { setPostReqResp } from '../../../../../stores/common/reducer';
import { IPostAppBundleAlias, IPutAppBundleAlias } from '../../../../../types/appBundleAlias';
import { IAppBundleVersion } from '../../../../../types/appBundleVersion';
import { ICommonModalProps } from '../../../../../types/common';
import { IModalWrapperButton } from '../../../../../types/modal';

interface ICreateAppBundleAlias extends ICommonModalProps {
  dataToUpdateId?: string,
}

const CreateAppBundleAliasModal: FC<ICreateAppBundleAlias> = ({
  isOpen,
  dataToUpdateId,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { appBundleId = '' } = useParams()
  const { appBundleAlias, isAppBundleAliasLoading } = useAppBundleAlias()
  const { appBundleVersionList } = useAppBundleVersion()
  const { postReqResp } = useCommon()
  const formRef = useRef<Form | null>(null)
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)
  const [defaultBundleVersion, setDefaultBundleVersion] = useState<IAppBundleVersion | null>(null)
  const headerText = dataToUpdateId ? 'Update Alias' : 'Create Alias';

  const [inventorVersions, setInventorVersions] = useState<string[]>([]);

  const [formState, setFormState] = useState<any>({
    Name: '',
    DesignGearVersion: '',
    InventorVersion: inventorVersions[0],
    BundleVersionId: appBundleVersionList?.data[0],
    AppBundleId: appBundleId
  })

  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    { buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click(), buttonDisabled: isAppBundleAliasLoading }
  ]

  useEffect(() => {
    dispatch(getAppBundleVersionList(appBundleId))
    return () => {
      dispatch(setAppBundleAlias(null))
    }
  }, [appBundleId])

  useEffect(() => {
    if (appBundleAlias?.bundleVersionId && appBundleVersionList?.data.length) {
      const presetBundleVersion = appBundleVersionList.data.find(item => item.id === appBundleAlias?.bundleVersionId)
      const bundleVersion = presetBundleVersion || appBundleVersionList?.data[0]
      formRef?.current?.valueSetter("BundleVersionId", bundleVersion)
      setDefaultBundleVersion(bundleVersion)
    }
    else {
      formRef?.current?.valueSetter("BundleVersionId", appBundleVersionList?.data[0])
      setDefaultBundleVersion(appBundleVersionList?.data[0] || null)
    }

  }, [appBundleVersionList])

  useEffect(() => {
    if (dataToUpdateId) {
      dispatch(setAppBundleAlias(null))
      dispatch(getAppBundleAlias(dataToUpdateId))
    }
    else {
      setFormState({
        Name: '',
        DesignGearVersion: '',
        InventorVersion: inventorVersions[0],
        BundleVersionId: appBundleVersionList?.data[0],
        AppBundleId: appBundleId
      })
    }
  }, [dataToUpdateId])
  useEffect(() => {
    if (appBundleAlias) {
      setFormState({
        ...formState,
        Name: appBundleAlias.name,
        DesignGearVersion: appBundleAlias.designGearVersion,
        InventorVersion: appBundleAlias.inventorVersion,
        BundleVersionId: appBundleVersionList?.data[0],
        AppBundleId: appBundleId
      })
      if (!dataToUpdateId)
        setFormState({ ...formState })
      dispatch(getAppBundleVersionList(appBundleId))
    }
  }, [appBundleAlias])
  useEffect(() => {
    if (postReqResp) {
      onClose()
      dispatch(setPostReqResp(''))
    }
  }, [postReqResp, isAppBundleAliasLoading])

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch('/client-config.json');
        const config = await response.json();
        setInventorVersions(config.autodeskInventorVersions);
      } catch (error) {
        console.error('Error loading config:', error);
      }
    };

    loadConfig()
    formRef?.current?.valueSetter("InventorVersion", inventorVersions[0])
  }, [])

  const onSubmitLocal = (formData: any) => {
    if (!formRef?.current?.isValid()) return

    const data = new FormData();
    data.append('Name', formData.Name as string)
    data.append('DesignGearVersion', formData.DesignGearVersion)
    data.append('InventorVersion', formData.InventorVersion)
    data.append('BundleVersionId', formData.BundleVersionId.id)
    data.append('AppBundleId', formData.AppBundleId)

    if (!dataToUpdateId) {
      dispatch(postAppBundleAlias(data as IPostAppBundleAlias))
    }
    else {
      data.append('id', dataToUpdateId)
      dispatch(putAppBundleAlias(data as IPutAppBundleAlias))
    }
  }

  return (
    <ModalWrapper
      headerText={headerText}
      isOpen={isOpen}
      onClose={onClose}
      buttons={modalButtons}
      className={'create-appbundle-alias-form'}
    >
      <Form
        onSubmit={onSubmitLocal}
        ignoreModified={true}
        initialValues={formState}
        key={JSON.stringify(formState)}
        ref={formRef}
        render={(formRenderProps) => (
          <FormElement className={'modal-body'}>
            {isAppBundleAliasLoading ? (
              <Spinner />
            ) : (
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-3">
                  <Field
                    name="Name"
                    component={CInput}
                    label="Alias name"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="DesignGearVersion"
                    component={CInput}
                    label="DesignGear version"
                    validator={isEmpty}
                    maxLength={50}
                  />
                </div>
                {/*<div className="mb-3">*/}
                {/*  <Field*/}
                {/*    name="InventorVersion"*/}
                {/*    component={DropDownList}*/}
                {/*    label="Inventor version"*/}
                {/*    validator={isEmpty}*/}
                {/*    maxLength={50}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="mb-3">
                  <div>Inventor version</div>
                  <FieldWrapper>
                    <DropDownList
                      name="Inventor Version"
                      style={{ width: "50%" }}
                      data={inventorVersions}
                      defaultValue={formRef.current?.valueGetter("InventorVersion")}
                      onChange={val => formRef?.current?.valueSetter("InventorVersion", val.target.value)}
                      required={true}
                    />
                  </FieldWrapper>
                </div>
                <div className="mb-3">
                  <div>Select app bundle version</div>
                  <FieldWrapper>
                    <DropDownList
                      name="BundleVersionId"
                      style={{ width: "50%" }}
                      data={appBundleVersionList?.data}
                      textField="version"
                      dataItemKey="id"
                      defaultValue={defaultBundleVersion}
                      loading={!appBundleVersionList?.data.length}
                      onChange={val => formRef?.current?.valueSetter("BundleVersionId", val.target.value)}
                      required={true}
                    />
                  </FieldWrapper>
                </div>
              </fieldset>
            )}
            <Button
              type="submit"
              hidden={true}
              ref={formSubmitBtnRef}
            >
              submit
            </Button>
          </FormElement>
        )}
      />
    </ModalWrapper>
  )
}

export default CreateAppBundleAliasModal
